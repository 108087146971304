<template>
<div class="accounttickets">
     <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Account</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Support tickets</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Support tickets</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-3">
      <div class="row">
        
        <!-- Sidebar-->
        <AccountSidebarMenu />

        <!-- Content  -->
        <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
            <div class="form-inline">
              <label class="text-light opacity-75 text-nowrap mr-2 d-none d-lg-block" for="order-sort">Sort orders:</label>
              <select class="form-control custom-select" id="order-sort">
                <option>All</option>
                <option>Open</option>
                <option>Closed</option>
              </select>
            </div><a class="btn btn-primary btn-sm d-none d-lg-inline-block" href="account-signin.html"><i class="czi-sign-out mr-2"></i>Sign out</a>
          </div>
          <!-- Tickets list-->
          <div class="table-responsive font-size-md">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>Ticket Subject</th>
                  <th>Date Submitted | Updated</th>
                  <th>Type</th>
                  <th>Priority</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-3"><a class="nav-link-style font-weight-medium" href="account-single-ticket.html">My new ticket</a></td>
                  <td class="py-3">09/27/2019 | 09/30/2019</td>
                  <td class="py-3">Website problem</td>
                  <td class="py-3"><span class="badge badge-warning m-0">High</span></td>
                  <td class="py-3"><span class="badge badge-success m-0">Open</span></td>
                </tr>
                <tr>
                  <td class="py-3"><a class="nav-link-style font-weight-medium" href="account-single-ticket.html">Another ticket</a></td>
                  <td class="py-3">08/21/2019 | 08/23/2019</td>
                  <td class="py-3">Partner request</td>
                  <td class="py-3"><span class="badge badge-info m-0">Medium</span></td>
                  <td class="py-3"><span class="badge badge-secondary m-0">Closed</span></td>
                </tr>
                <tr>
                  <td class="py-3"><a class="nav-link-style font-weight-medium" href="account-single-ticket.html">Yet another ticket</a></td>
                  <td class="py-3">11/19/2018 | 11/20/2018</td>
                  <td class="py-3">Complaint</td>
                  <td class="py-3"><span class="badge badge-danger m-0">Urgent</span></td>
                  <td class="py-3"><span class="badge badge-secondary m-0">Closed</span></td>
                </tr>
                <tr>
                  <td class="py-3"><a class="nav-link-style font-weight-medium" href="account-single-ticket.html">My old ticket</a></td>
                  <td class="py-3">06/19/2018 | 06/20/2018</td>
                  <td class="py-3">Info inquiry</td>
                  <td class="py-3"><span class="badge badge-success m-0">Low</span></td>
                  <td class="py-3"><span class="badge badge-secondary m-0">Closed</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr class="mb-4">
          <div class="text-right">
            <button class="btn btn-primary" data-toggle="modal" data-target="#open-ticket">Submit new ticket</button>
          </div>
        </section>
      </div>
    </div>
</div>
</template>

<script>
import AccountSidebarMenu from '@/components/AccountSidebarMenu.vue'

export default {
  name: 'AccountTickets',
  components: {
    AccountSidebarMenu

  }

}
</script>