<template>
   <div>
     <Breadcrumb />
      <!-- Page Content-->
      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">

          <!-- Sidebar-->
        <aside class="col-lg-4">
          <!-- Sidebar-->
          <div class="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
            <div class="cz-sidebar-header box-shadow-sm">
              <button class="close ml-auto" type="button" data-dismiss="sidebar" aria-label="Close"><span class="d-inline-block font-size-xs font-weight-normal align-middle">Close sidebar</span><span class="d-inline-block align-middle ml-2" aria-hidden="true">&times;</span></button>
            </div>
            <div class="cz-sidebar-body">
             
              <!-- CATEGORIES -->
              <Category />

              <!-- Price range-->
              <div class="widget mb-4 pb-4 border-bottom">
                <h3 class="widget-title">Price</h3>
                  <!-- <label for="">Max</label><input type="number" class="form-control w-25 " v-model="maximum"> -->
        
                <div class="cz-range-slider" >
                  <!-- <div class="cz-range-slider-ui"></div> -->
                   <input type="range" name="" id="" class="custom-range" min="0" max="8000000" v-model="maximum">
                  <div class="d-flex pb-1">
                    <div class="w-50 pr-2 mr-2">
                       <label for="">Min</label>
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend"><span class="input-group-text">Rp</span></div>
                        <input class="form-control cz-range-slider-value-min" type="text">
                      </div>
                    </div>
                    <div class="w-50 pl-2">
                       <label for="">Max</label>
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend"><span class="input-group-text">Rp</span></div>
                        <input class="form-control cz-range-slider-value-max" type="number" v-model="maximum">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <!-- Filter by Color-->
              <!-- <div class="widget">
                <h3 class="widget-title">Color</h3>
                <div class="d-flex flex-wrap">
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-blue-gray">
                    <label class="custom-option-label rounded-circle" for="color-blue-gray"><span class="custom-option-color rounded-circle" style="background-color: #b3c8db;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-blue-gray">Blue-gray</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-burgundy">
                    <label class="custom-option-label rounded-circle" for="color-burgundy"><span class="custom-option-color rounded-circle" style="background-color: #ca7295;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-burgundy">Burgundy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-teal">
                    <label class="custom-option-label rounded-circle" for="color-teal"><span class="custom-option-color rounded-circle" style="background-color: #91c2c3;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-teal">Teal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-brown">
                    <label class="custom-option-label rounded-circle" for="color-brown"><span class="custom-option-color rounded-circle" style="background-color: #9a8480;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-brown">Brown</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-coral-red">
                    <label class="custom-option-label rounded-circle" for="color-coral-red"><span class="custom-option-color rounded-circle" style="background-color: #ff7072;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-coral-red">Coral red</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-navy">
                    <label class="custom-option-label rounded-circle" for="color-navy"><span class="custom-option-color rounded-circle" style="background-color: #696dc8;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-navy">Navy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-charcoal">
                    <label class="custom-option-label rounded-circle" for="color-charcoal"><span class="custom-option-color rounded-circle" style="background-color: #4e4d4d;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-charcoal">Charcoal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-sky-blue">
                    <label class="custom-option-label rounded-circle" for="color-sky-blue"><span class="custom-option-color rounded-circle" style="background-color: #8bcdf5;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-sky-blue">Sky blue</label>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </aside>
        <!-- Content  -->

    <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
            <div class="d-flex flex-wrap">
              <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                <label class="text-light opacity-75 text-nowrap mr-2 d-none d-sm-block" for="sorting">Sort by:</label>
                <select class="form-control custom-select" id="sorting">
                  <option>Popularity</option>
                  <!-- <option>Low - Hight Price</option>
                  <option>High - Low Price</option>
                  <option>Average Rating</option>
                  <option>A - Z Order</option>
                  <option>Z - A Order</option> -->
                </select>
                <span class="font-size-sm text-light opacity-75 text-nowrap ml-2 d-none d-md-block">
                  <!-- of 287 products -->
                  </span>
              </div>
            </div>
            <div class="d-flex pb-3"><a class="nav-link-style nav-link-light mr-3" href="" ><i class="czi-arrow-left"></i></a><span class="font-size-md text-light">{{ this.page }} / {{ this.lastPage }}</span><a class="nav-link-style nav-link-light ml-3" href="#"><i class="czi-arrow-right"></i></a></div>
           
          </div>

  

       

          <!-- Products grid-->
          <div class="row mx-n2" v-if="products.length > 0">
            <!-- Product-->
            
            <div  class="col-md-4 col-sm-6 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
            <div  v-if="itemProduct.price > Number(maximum)" >
              
                <div class="card products">
                  <!-- <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button> -->
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100" style="height:200px; background:#ccc;">
                    <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body py-2 px-0"><a class="product-meta d-block font-size-xs pb-1" href="#"><small>CODE: {{ itemProduct.kode }}</small></a>
                    <h3 class="product-title font-size-sm"><router-link v-bind:to="'/product/detail/'+itemProduct.id" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      {{ itemProduct.weight }} Carats <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    </h3>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price"><span class="text-accent" style="font-size:12px;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span></div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                  </div>

                  <!-- <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div> -->


                </div>
                <hr class="d-sm-none">
              </div>
            </div>
          </div>


            <div class="row" v-else>
              <div class="col-md-12 text-center" >
                <p>
                  Produk belum tersedia saat ini. {{ products.length }}
                </p>
              </div>
          </div>
          <hr class="my-3">
           <!-- <pagination :data="products" @pagination-change-page="getResult"></pagination> -->
          <!-- Pagination-->
          <!-- <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#"><i class="czi-arrow-left mr-2"></i>Prev</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
              <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="sr-only">(current)</span></span></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="czi-arrow-right ml-2"></i></a></li>
            </ul>
          </nav> -->
  <div class="overflow-auto">
    <!-- Use text in props -->
    <b-pagination-nav v-model="page"  @input="go"
      :number-of-pages="lastPage"
       :per-page="perPage"
      base-url="?page="
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination-nav>
    </div>
    <p class="mt-3">Current Page: {{ this.page }}</p>
    <p class="mt-3">Last Page: {{ this.lastPage }}</p>
    <p class="mt-3">Total: {{ this.totalProduct }}</p>
    <!-- <p >Subcategories: {{ $attrs.subcategories_id }} | {{ this.$route.params.subcategories_id }}</p> -->


          
        </section>


          <!-- <Sidebar /> -->
          <!-- <ProductGrid /> -->
        </div>
      </div>


   </div>
   
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Category from '@/components/Category.vue'

// import Sidebar from '@/components/Sidebar.vue'
// import ProductGrid from '@/components/ProductGrid.vue'

export default {
  name: 'ShopSubcategory',
  components: {
      Breadcrumb,
      Category
      // ProductGrid,
      // Sidebar
  },
   data() {
      return {

        maximum: 0,
        products: [],
        keranjangUser:[],
        page: this.currentPage,
        
        // page: 1,
        perPage: 21,
        lastPage: this.lastPage,
        totalProduct: this.totalProduct,
        nextPageUrl: this.next_page_url, 
        prevPageUrl: this.prev_page_url,
       


      }
    }, 
    created(){
      this.subcategories_id=this.$route.params.subcategories_id;
      this.go()
    },
    //  mounted() {

    //     // this.axios.get("/products?page=4")
    //     // .then(res => (this.products = res.data.data.data))
    //     // //  eslint-disable-next-line no-console
    //     // .catch(err => console.log(err));

    //     this.axios.get('/products?page='+this.page)
    //     .then((res) => {
    //         this.products = res.data.data.data
    //         this.page = res.data.data.current_page
    //         this.lastPage = res.data.data.last_page
    //         this.totalProduct = res.data.data.total
        
    //     })
    //     //  eslint-disable-next-line no-console
    //     .catch(err => console.log(err));

    // },
    methods: {
      
      go(){

        let url = '/products?subcategories_id='+this.subcategories_id
        this.axios.get(url)
        .then((res) => {
            // let { data } = res.data.data.data
            // let { meta } = res.data.data
            this.products = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.lastPage = res.data.data.last_page
            this.totalProduct = res.data.data.total
            this.next_page_url = res.data.data.next_page_url
            this.prev_page_url = res.data.data.prev_page_url
            // this.subcategories_id = res.data.data.subcategories_id
             

        })
        .catch((error) => {
            let { res } = error
            console.log(res)
        })
      },
 
      saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
        var productStored = {
          "id": idProduct,
          "name": nameProduct,
          "price": priceProduct,
          "photo": photoProduct
        }
        this.keranjangUser.push(productStored);
        const parsed = JSON.stringify(this.keranjangUser);
        localStorage.setItem('keranjangUser', parsed);
      },
      getResults(page){
 
        let uri = '/products?subcategories_id'+this.subcategories_id+'page=' + page;
        this.axios.get(uri).then(response => {
                    return response.data;
                }).then(data => {
                    this.products = data;
                }); 
      },
    },
    mounted() {
    // if (localStorage.getItem('keranjangUser')) {
    //   try {
    //     this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
    //   } catch(e) {
    //     localStorage.removeItem('keranjangUser');
    //   }
    // }

         this.axios.get("/products",{
            params: {
            subcategories_id: this.$route.params.subcategories_id
          }

         })
        .then(res => (
          
          this.setDataPicture(res.data.data)))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    }
   
};

</script>


<style scoped>

</style>

