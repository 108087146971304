<template>
<div class="shopdetail">
<Breadcrumb />
 <!-- Page Content-->

    <div class="container">
      <!-- Gallery + details-->
      <div class="bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5 product-shop spad page-details">
        <div class="px-lg-3">
          <div class="row">
            

            <!-- Product gallery-->
            <div class="col-lg-7 pr-lg-0 pt-lg-4" >

                  <!-- <div class="product-pic-zoom mb-2">
                    <img class="product-big-img w-100" :src="gambar_default" alt="Product image">
                  </div>
                
                <div class="product-thumbs" v-if="productDetails.galleries.length >0">
                  <carousel :dots="false" :nav="false" class="product-thumbs-track ps-slider">

                    <div v-for="ss in productDetails.galleries" :key="ss.id" class="pt" @click="changeImage(ss.photo)" :class="ss.photo == gambar_default ? 'active' : '' ">
                      <img :src="ss.photo" alt="Product thumb" style="object-fit:cover; width:100%; height:200px;">
                    </div>
                
                  </carousel>
                </div> -->
              <carousel  class="product-slider" :items="1" :nav="false"  >
              <!-- Product-->
              <div class="product-item" v-for="ss in productDetails.galleries" v-bind:key="ss.id">

                <img :src="ss.photo" class="product-big-img w-100" alt="Product" >
                 
              </div>
            </carousel>

            </div>

           

            <!-- Product details-->
            <div class="col-lg-5 pt-4 pt-lg-0">
              <div class="product-details ml-auto pb-3">

                <div class="d-flex justify-content-between align-items-center mb-2">
                  <a href="#reviews" data-scroll>
                    <span class="d-inline-block font-size-sm text-body align-middle mt-1 ml-1">CODE #{{ productDetails.kode}}<br>
                      {{ productDetails.type }}</span></a>
                  <!-- <button class="btn-wishlist mr-0 mr-lg-n3" type="button" data-toggle="tooltip" title="Add to wishlist"><i class="czi-heart"></i></button> -->
                </div>
                <h2 style="color:#78367f;"><b>{{ productDetails.name }}</b></h2>
                <div class="mb-3">
                  <!-- <del class="text-muted font-size-lg mr-3">Rp {{ productDetails.price }}</del><br> -->
                  <span class="h3 font-weight-bold  mr-1" style="font-size:18px; color:grey;">Rp {{ productDetails.price.toLocaleString('id-ID') }}</span>
                  <!-- <span class="badge badge-danger badge-shadow align-middle mt-n2">Sale</span> -->
                </div>

                
                

                <!-- Product panels-->
                <!-- <div class="accordion mb-4" id="productPanels">
                  <div class="card"> -->
                    <!-- <div class="card-header">
                      <h3 class="accordion-heading"><a href="#productInfo" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="productInfo"><i class="czi-announcement text-muted font-size-lg align-middle mt-n1 mr-2"></i>Stone Information And Specification<span class="accordion-indicator"></span></a></h3>
                    </div> -->

                    <!-- <div class="collapse show" id="productInfo" data-parent="#productPanels">
                      <div class="card-body"> -->
                        <!-- <h6 class="font-size-sm mb-2">Description</h6> -->

                        <table class="table table-hover" style="font-size:15px;">
                          <tbody>
                            <tr>
                              <td>Category</td>
                              <td>: {{ productDetails.categories.name }}</td>
                            </tr>
                            <tr>
                              <td>Type</td>
                              <td>: {{ productDetails.subcategories.name }}</td>
                            </tr>
                            <tr>
                              <td>Weight</td>
                              <td>: {{ productDetails.weight }} ct</td>
                            </tr>
                            <tr>
                              <td>Dimensions</td>
                              <td>: {{ productDetails.dimensions }}</td>
                            </tr>
                            <tr>
                              <td>Color</td>
                              <td>: {{ productDetails.colors.name }}</td>
                            </tr>
                            <tr>
                              <td>Shape</td>
                              <td>: {{ productDetails.shapes.name }}</td>
                            </tr>
                            <tr>
                              <td>Cutting</td>
                              <td>: {{ productDetails.cuttings.name }}</td>
                            </tr>
                             <tr>
                              <td>Clarity</td>
                              <td>: </td>
                            </tr>
                            <tr>
                              <td>Transparancy</td>
                              <td>:</td>
                            </tr>
                            <!-- <tr>
                              <td>Treatment</td>
                              <td>: {{ productDetails.treatment_name }}</td>
                            </tr> -->
                            <tr>
                              <td>Origin Est.</td>
                              <td>: {{ productDetails.origins.name }}</td>
                            </tr>
                             <tr>
                              <td>Note</td>
                              <td><span style="float:left;">: &nbsp;</span>  <p style="float:left;" v-html="productDetails.description"></p></td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <div class="mb-grid-gutter" v-if="productDetails.stock > 0">
                                <div class="form-group d-flex align-items-center">
                                  <select class="custom-select mr-3" style="width: 5rem;" hidden>
                                    <option value="1">1</option>
                                  </select>
                                  <!-- <router-link to="/cart"> -->
                                  <!-- <a @click="saveKeranjang(productDetails.id, productDetails.name, productDetails.price, productDetails.galleries[0].photo)" class="btn btn-primary btn-shadow btn-block" type="submit"><i class="czi-cart font-size-lg mr-2"></i>Add to Cart</a> -->
                                   <button @click="buy" :disabled="productDetails.stock==0" class="btn btn-primary btn-shadow btn-block"><i class="czi-cart font-size-lg mr-2"></i>Add to Cart</button>
                                  <!-- </router-link> -->
                                </div>
                              </div>

                              <div class="mb-grid-gutter" v-else>
                                <div class="form-group d-flex align-items-center">
                                  <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" disabled>Sold Out</button>
                                </div>
                              </div>
                     
                                <div class="btn-group btn-group-sm d-flex justify-content-center" role="group" aria-label="Basic example" >
                          <a href="http://wa.me/628176781986" target="_blank" role="button"  class="btn " style="background-color: #E9EDF6; color:#78367f"><i class="fa fa-whatsapp"></i> Whatsapp</a>
                           <a href="tel:+62218354050" role="button" class="btn border-left " style="background-color: #E9EDF6; color:#78367f;"><i class="fa fa-phone"></i> Call Us</a>
                          <a href="mailto:contact@batumuliashop.com" role="button" class="btn border-left" style="background-color: #E9EDF6; color:#78367f"><i class="fa fa-envelope-o"></i> Email</a>
                        </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>


                      
                 
                    <!-- </div> -->
                  <!-- </div> -->

                  <!-- <div class="card"> -->
                    <!-- <div class="card-header">
                      <h3 class="accordion-heading"><a class="collapsed" href="#shippingOptions" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shippingOptions"><i class="czi-delivery text-muted lead align-middle mt-n1 mr-2"></i>Shipping options<span class="accordion-indicator"></span></a></h3>
                    </div> -->
                    <!-- <div class="collapse" id="shippingOptions" data-parent="#productPanels">
                      <div class="card-body font-size-sm">
                        <div class="d-flex justify-content-between border-bottom pb-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Courier</div>
                            <div class="font-size-sm text-muted">2 - 4 days</div>
                          </div>
                          <div>$26.50</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Local shipping</div>
                            <div class="font-size-sm text-muted">up to one week</div>
                          </div>
                          <div>$10.00</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Flat rate</div>
                            <div class="font-size-sm text-muted">5 - 7 days</div>
                          </div>
                          <div>$33.85</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">UPS ground shipping</div>
                            <div class="font-size-sm text-muted">4 - 6 days</div>
                          </div>
                          <div>$18.00</div>
                        </div>
                        <div class="d-flex justify-content-between pt-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Local pickup from store</div>
                            <div class="font-size-sm text-muted">&mdash;</div>
                          </div>
                          <div>$0.00</div>
                        </div>
                      </div>
                    </div> -->
                  <!-- </div>
                </div> -->
                <!-- Sharing-->
                <!-- <h6 class="d-inline-block align-middle font-size-base my-2 mr-2">Follow:</h6><a class="share-btn sb-instagram mr-2 my-2" href="https://www.instagram.com/batumuliashop"><i class="czi-instagram"></i>Instagram</a><a class="share-btn sb-facebook my-2" href="https://id-id.facebook.com/ghulamgem/"><i class="czi-facebook"></i>Facebook</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <!-- Product description section 2-->
      <div class="row align-items-center py-4 py-lg-5">
        <div class="col-lg-5 col-md-6 offset-lg-1"><img class="d-block rounded-lg" src="/img/shop/single/prod-map.png" alt="Map"></div>
        <div class="col-lg-4 col-md-6 offset-lg-1 py-4">
          <h2 class="h3 mb-4 pb-2">Handmade just for you.</h2>
          <p class="font-size-sm text-muted pb-md-2">Our designs are made by hand, and we are perfectionists when it comes to the quality of your jewelry. Our attention to detail and old-world techniques ensure that your piece will be handled with care throughout the process, from the jewelry bench to your finger.</p>
        </div>
      </div>
    </div>
    <!-- Reviews-->
    <!-- <div class="border-top border-bottom my-lg-3 py-5">
      <div class="container pt-md-2" id="reviews"> -->
        <!-- <div class="row pb-3">
          <div class="col-lg-4 col-md-5">
            <h2 class="h3 mb-4">74 Reviews</h2>
            <div class="star-rating mr-2"><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star font-size-sm text-muted mr-1"></i></div><span class="d-inline-block align-middle">4.1 Overall rating</span>
            <p class="pt-3 font-size-sm text-muted">58 out of 74 (77%)<br>Customers recommended this product</p>
          </div>
          <div class="col-lg-8 col-md-7">
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">5</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar bg-success" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">43</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">4</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 27%; background-color: #a7e453;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">16</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">3</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 17%; background-color: #ffda75;" aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">9</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">2</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 9%; background-color: #fea569;" aria-valuenow="9" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">4</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">1</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar bg-danger" role="progressbar" style="width: 4%;" aria-valuenow="4" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">2</span>
            </div>
          </div>
        </div>
        <hr class="mt-4 pb-4 mb-3"> -->
        <div class="row">
          <!-- Reviews list-->
          <div class="col-md-7">
            <!-- <div class="d-flex justify-content-end pb-4">
              <div class="form-inline flex-nowrap">
                <label class="text-muted text-nowrap mr-2 d-none d-sm-block" for="sort-reviews">Sort by:</label>
                <select class="custom-select custom-select-sm" id="sort-reviews">
                  <option>Newest</option>
                  <option>Oldest</option>
                  <option>Popular</option>
                  <option>High rating</option>
                  <option>Low rating</option>
                </select>
              </div>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/01.jpg" alt="Rafael Marquez"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Rafael Marquez</h6><span class="font-size-ms text-muted">June 28, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est...</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>1.5mm Simple Thin Plain Wedding Band, 14k Solid Gold Smooth</li>
              </ul>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/02.jpg" alt="Barbara Palson"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Barbara Palson</h6><span class="font-size-ms text-muted">May 17, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>Consequuntur magni, voluptatem sequi, tempora</li>
              </ul>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/03.jpg" alt="Daniel Adams"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Daniel Adams</h6><span class="font-size-ms text-muted">May 8, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i><i class="sr-star czi-star"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>Consequuntur magni, voluptatem sequi</li>
              </ul>
            </div> -->
            <!-- <div class="text-center">
              <button class="btn btn-outline-accent" type="button"><i class="czi-reload mr-2"></i>Load more reviews</button>
            </div> -->
          </div>
          <!-- Leave review form-->
          <!-- <div class="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
            <div class="bg-secondary py-grid-gutter px-grid-gutter rounded-lg">
              <h3 class="h4 pb-2">Write a review</h3>
              <form class="needs-validation" method="post" novalidate>
                <div class="form-group">
                  <label for="review-name">Your name<span class="text-danger">*</span></label>
                  <input class="form-control" type="text" required id="review-name">
                  <div class="invalid-feedback">Please enter your name!</div><small class="form-text text-muted">Will be displayed on the comment.</small>
                </div>
                <div class="form-group">
                  <label for="review-email">Your email<span class="text-danger">*</span></label>
                  <input class="form-control" type="email" required id="review-email">
                  <div class="invalid-feedback">Please provide valid email address!</div><small class="form-text text-muted">Authentication only - we won't spam you.</small>
                </div>
                <div class="form-group">
                  <label for="review-rating">Rating<span class="text-danger">*</span></label>
                  <select class="custom-select" required id="review-rating">
                    <option value="">Choose rating</option>
                    <option value="5">5 stars</option>
                    <option value="4">4 stars</option>
                    <option value="3">3 stars</option>
                    <option value="2">2 stars</option>
                    <option value="1">1 star</option>
                  </select>
                  <div class="invalid-feedback">Please choose rating!</div>
                </div>
                <div class="form-group">
                  <label for="review-text">Review<span class="text-danger">*</span></label>
                  <textarea class="form-control" rows="6" required id="review-text"></textarea>
                  <div class="invalid-feedback">Please write a review!</div><small class="form-text text-muted">Your review must be at least 50 characters.</small>
                </div>
                <button class="btn btn-primary btn-shadow btn-block" type="submit">Submit a Review</button>
              </form>
            </div>
          </div> -->
        <!-- </div>
      </div> -->
    </div>
    <ProductYouMayLike />
</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import ProductYouMayLike from '@/components/ProductYouMayLike.vue'
import carousel from 'vue-owl-carousel'
import { mapGetters, mapActions } from 'vuex'


export default {
  name: 'ShopDetail',
  components: {
      Breadcrumb,
      ProductYouMayLike,
      carousel
  },

  //   data: () => ({
  //   productDetails: {}, // objek product
  //   origins_name: this.origins_name
  // }),
  data() {
      return {

         productDetails: {}, // objek product


      }
    }, 
   created(){
    this.go()
  },
  computed: {
    ...mapGetters({
        carts: 'cart/carts',
    })
  },
  methods: {
     ...mapActions({
        addCart   : 'cart/add',
        setAlert  : 'alert/set',
    }),
    buy(){
        this.addCart(this.productDetails)
        //this.$store.dispatch('cart/add', this.book)
        this.setAlert({
            status : true,
            color  : 'success',
            text   : 'Added to cart',
        })
    },
    go(){
        // let { slug } = this.$route.params
        // let url = '/products/'+slug
        // url = url + '?page='+this.page
        // url = encodeURI(url)
        let url = '/products?id='+this.id
        this.axios.get(url)
        .then((response) => {
            // let { data } = response.data.data.data
            this.productDetails = response.data.data.data
            this.id = response.data.data.id
            // this.origins_name = response.data.origins_name
        })
        .catch((error) => {
            let { responses } = error
            console.log(responses)
        }) 
    },
    changeImage(urlImage) {
      this.gambar_default = urlImage;
    },
    setDataPicture(data) {
      // replace object productDetails dengan data dari API
      this.productDetails = data;
      // replace value gambar default dengan data dari API (galleries)
      this.gambar_default = data.galleries[0].photo;
    },
    // saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
    //   var productStored = {
    //     "id": idProduct,
    //     "name": nameProduct,
    //     "price": priceProduct,
    //     "photo": photoProduct
    //   }
    //   this.keranjangUser.push(productStored);
    //   const parsed = JSON.stringify(this.keranjangUser);
    //   localStorage.setItem('keranjangUser', parsed);
    // }
  },
  
  mounted() {
    // if (localStorage.getItem('keranjangUser')) {
    //   try {
    //     this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
    //   } catch(e) {
    //     localStorage.removeItem('keranjangUser');
    //   }
    // }

         this.axios.get("/products",{
            params: {
            id: this.$route.params.id
          }

         })
        .then(res => (
          
          this.setDataPicture(res.data.data)))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    }

    
}


</script>

<style scoped>
.product-thumbs .pt {
  margin-right: 10px;
}

</style>

