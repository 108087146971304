<template>
  <!-- Product carousel (You may also like)-->
  <div class="container py-5 my-md-3">
    <h2 class="h3 text-center pb-4">You may also like</h2>

    <!-- <div v-if="products.length > 0"> -->
   
      <section class="hero-section ">
      <carousel class="hero-items card-deck" :items="4" :autoplay="true" :nav="true"  >
        
          <!-- Product-->
                <div class="card" style="width: 18rem;border:none;" v-for="itemProduct in products" v-bind:key="itemProduct.id" >
                  <!-- <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button> -->
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-center" style="height:200px; background:#ccc;">
                  <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body py-1 px-0 font-size-sm"><small>CODE: {{ itemProduct.kode }}</small>
                    <h3 class="product-title font-size-lg"><router-link v-bind:to="'/product/detail/'+itemProduct.id" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      {{ itemProduct.weight }} Carats <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price"><span class="text-accent" style="font-size:12px;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span></div>
                     
                    </div>
                    </h3>
                    
                  </div>

   
                </div>

             
         
             
           
          </carousel>
      </section>

    
 
  </div>
</template>

<script type="module">
import carousel from 'vue-owl-carousel'

// import axios from "axios";

export default {
  name: 'ProductYouMayLike',
  components: {
    carousel
 
  },
  data() {
      return {
        products: [],
   
      }
    }, 
     mounted() {
        let url = '/products-flashsale'
        this.axios.get(url)
        .then(res => (this.products = res.data.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    },
    methods: {
 
      
    },

}


</script>

<style scoped>
  
</style>