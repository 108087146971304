<template>
    <!-- Products grid (New Arrival)-->
    <section class="container pt-md-3 pb-5 mb-md-3">
      <h2 class="h3 text-center my-5">New Arrival</h2>
      <!-- <div class="row pt-4 mx-n2 row-cols-2 row-cols-md-3 row-cols-xl-5" v-if="products.length > 0"> -->
        <div class="row pt-4 mx-n2 " v-if="products.length > 0">
        <!-- Product-->
        <div class="col-6 col-md-4 col-lg-3 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
          <div class="card products">
                  <!-- <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button> -->
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-center" style="height:200px; background:#ccc;">
                    <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body pt-2 px-0"><a class="product-meta d-block font-size-sm" href="#"><small>CODE: {{ itemProduct.kode }}</small></a>
                    <h3 class="product-title font-size-lg"><router-link v-bind:to="'/product/detail/'+itemProduct.id" ><b>{{ itemProduct.name }}</b></router-link><br>
                  <small>
                      {{ itemProduct.weight }} Carats <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price"><span class="text-accent" style="font-size:12px;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span></div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h3>
                    
                  </div>

                  <!-- <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div> -->


                </div>
          <hr class="d-sm-none">
        </div>


      </div>

      <div class="row" v-else>
          <div class="col-md-12 text-center" >
            <p>
              Produk terbaru belum tersedia saat ini.
            </p>
          </div>
      </div>

      <!-- <div class="text-center pt-3"><a class="btn btn-outline-accent" href="/product/detail">More products<i class="czi-arrow-right ml-1"></i></a></div> -->
    </section>
</template>

<script>


export default {
    name: 'ProductNewArrival',
    data() {
      return {
        products: [],
        keranjangUser:[]
      }
    }, 
     mounted() {

        this.axios.get("/products-newarrival?limit=20")
        .then(res => (this.products = res.data.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    },
    methods: {
 
      saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
        var productStored = {
          "id": idProduct,
          "name": nameProduct,
          "price": priceProduct,
          "photo": photoProduct
        }
        this.keranjangUser.push(productStored);
        const parsed = JSON.stringify(this.keranjangUser);
        localStorage.setItem('keranjangUser', parsed);
      }
    },
   
};
</script>