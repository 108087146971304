<template>
    <div class="payment">
        <!-- Page Title-->
        <div class="page-title-overlap bg-dark pt-4">
        <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
                <li class="breadcrumb-item text-nowrap"><a href="shop-grid-ls.html">Shop</a>
                </li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
                </ol>
            </nav>
            </div>
            <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
            <h1 class="h3 text-light mb-0">Payment Information</h1>
            </div>
        </div>
        </div>
     <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <section class="col-lg-8">
          <!-- Steps-->
          <!-- <div class="steps steps-light pt-2 pb-3 mb-5"><a class="step-item active" href="shop-cart.html">
              <div class="step-progress"><span class="step-count">1</span></div>
              <div class="step-label"><i class="czi-cart"></i>Cart</div></a><a class="step-item active" href="checkout-details.html">
              <div class="step-progress"><span class="step-count">2</span></div>
              <div class="step-label"><i class="czi-user-circle"></i>Your details</div></a><a class="step-item active" href="checkout-shipping.html">
              <div class="step-progress"><span class="step-count">3</span></div>
              <div class="step-label"><i class="czi-package"></i>Shipping</div></a><a class="step-item active current" href="checkout-payment.html">
              <div class="step-progress"><span class="step-count">4</span></div>
              <div class="step-label"><i class="czi-card"></i>Payment</div></a><a class="step-item" href="checkout-review.html">
              <div class="step-progress"><span class="step-count">5</span></div>
              <div class="step-label"><i class="czi-check-circle"></i>Review</div></a></div> -->
        </section>  
          
          <!-- Payment methods accordion-->
          
          <h2 class="h6 pb-3 mb-2 mt-5">Choose payment method</h2>
          <!-- <v-container v-if="payment"> -->
            <div class="container my-5">
                <table>
                    <tr><th>Order ID</th><td>: {{ payment.order_id }}</td></tr>
                    <tr><th>Invoice Number</th><td> : {{ payment.invoice_number }}</td></tr>
                    <tr><th>Total Bill</th><td>: Rp. {{payment.total_bill.toLocaleString('id-ID') }}</td></tr>
                </table>
            </div>

               <h4 class="px-4">Transfer To</h4>
                <div class="container px-4 mt-3">
                    <table>
                        <tr>
                            <td><img src="img/bca.png" width="100px"></td>
                            <td>BCA KCP abc No Rek 123</td>
                        </tr>
                        <tr style="padding:20px">
                            <td><img src="img/mandiri.png"></td>
                            <td>BANK MANDIRI KCP xyz No Rek 456</td>
                        </tr>
                    </table>

                </div>

  
  
    </div>
    </div>
          <button class="btn btn-success mb-5 mx-5" color="success" @click="finish">
Finish
</button>
    </div>
</template>
<script>
 import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Payment',
  components: {
    

  },
  computed: {
      ...mapGetters({
            payment  : 'payment',
        }),
  },
  created(){
    if(this.payment==undefined){
        this.setAlert({
            status : true,
            text  : 'Payment undefined',
            color  : 'warning',
        })
        this.$router.push('/')
    }
}, 
methods: {
    ...mapActions({
        setAlert: 'alert/set',
    }),
    finish(){

            this.setAlert({
                status : true,
                text  : 'Transaction done',
                color  : 'success',
            })
            this.$router.push('/')
        }
},

}
</script>