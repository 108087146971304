<template>
<div class="checkoutreview">
    <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><router-link class="text-nowrap" to="/"><i class="czi-home"></i>Home</router-link></li><li class="breadcrumb-item"><router-link class="text-nowrap" to="/"><i class="czi-home"></i>Home</router-link></li>

              <li class="breadcrumb-item text-nowrap"><a href="#">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Checkout</h1>
        </div>
      </div>
    </div>
     <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <section class="col-lg-8">
          <!-- Steps-->
          <div class="steps steps-light pt-2 pb-3 mb-5"><a class="step-item active" href="shop-cart.html">
              <div class="step-progress"><span class="step-count">1</span></div>
              <div class="step-label"><i class="czi-cart"></i>Cart</div></a><a class="step-item active" href="checkout-details.html">
              <div class="step-progress"><span class="step-count">2</span></div>
              <div class="step-label"><i class="czi-user-circle"></i>Your details</div></a><a class="step-item active" href="checkout-shipping.html">
              <div class="step-progress"><span class="step-count">3</span></div>
              <div class="step-label"><i class="czi-package"></i>Shipping</div></a><a class="step-item active" href="checkout-payment.html">
              <div class="step-progress"><span class="step-count">4</span></div>
              <div class="step-label"><i class="czi-card"></i>Payment</div></a><a class="step-item active current" href="checkout-review.html">
              <div class="step-progress"><span class="step-count">5</span></div>
              <div class="step-label"><i class="czi-check-circle"></i>Review</div></a></div>
          <!-- Order details-->
          <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Review your order</h2>
          <!-- Item-->
          <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom" v-for="keranjang in keranjangUser" :key="keranjang.id" >
            <div class="media media-ie-fix d-block d-sm-flex text-center text-sm-left"><router-link class="d-inline-block mx-auto mr-sm-4" v-bind:to="'/product/detail/'+keranjang.id" style="width: 10rem;"><img :src="keranjang.photo" alt="Product"></router-link>
              <div class="media-body pt-2">
                <h3 class="product-title font-size-base mb-2"><router-link v-bind:to="'/product/detail/'+keranjang.id">{{ keranjang.name }}</router-link></h3>
                <div class="font-size-sm"><span class="text-muted mr-2">Size:</span>-</div>
                <div class="font-size-sm"><span class="text-muted mr-2">Color:</span>White &amp; Blue</div>
                <div class="font-size-lg text-accent pt-2">Rp {{ keranjang.price.toLocaleString('id-ID') }}</div>
              </div>
            </div>
            <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-right" style="max-width: 9rem;">
              <p class="mb-0"><span class="text-muted font-size-sm">Quantity:</span><span>&nbsp;1</span></p>
              <!-- <button class="btn btn-link px-0" type="button"><i class="czi-edit mr-2"></i><span class="font-size-sm">Edit</span></button> -->
            </div>
          </div>
         
          <!-- Client details-->
          <div class="bg-secondary rounded-lg px-4 pt-4 pb-2">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="h6">Shipping to:</h4>
                <ul class="list-unstyled font-size-sm">
                  <li><span class="text-muted">Client:&nbsp;</span>Susan Gardner</li>
                  <li><span class="text-muted">Address:&nbsp;</span>44 Shirley Ave. West Chicago, IL 60185, USA</li>
                  <li><span class="text-muted">Phone:&nbsp;</span>+1 (808) 764 554 330</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <h4 class="h6">Payment method:</h4>
                <ul class="list-unstyled font-size-sm">
                  <li><span class="text-muted">Credit Card:&nbsp;</span>**** **** **** 5300</li>
                </ul>
              </div>
            </div>
          </div>

           <!-- Shipping address-->
          <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Shipping address</h2>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-fn">First Name</label>
                <input class="form-control" type="text" id="checkout-fn" v-model="customerInfo.name">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-ln">Last Name</label>
                <input class="form-control" type="text" id="checkout-ln" >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-email">E-mail Address</label>
                <input class="form-control" type="email" id="checkout-email" v-model="customerInfo.email">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-phone">Phone Number</label>
                <input class="form-control" type="text" id="checkout-phone" v-model="customerInfo.number">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-company">Company</label>
                <input class="form-control" type="text" id="checkout-company">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-country">Country</label>
                <select class="form-control custom-select" id="checkout-country">
                  <option>Choose country</option>
                  <option>Indonesia</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-city">City</label>
                <select class="form-control custom-select" id="checkout-city">
                  <option>Choose city</option>
                  <option>Amsterdam</option>

                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-zip">ZIP Code</label>
                <input class="form-control" type="text" id="checkout-zip">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-address-1">Address 1</label>
                <input class="form-control" type="text" id="checkout-address-1" v-model="customerInfo.address">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="checkout-address-2">Address 2</label>
                <input class="form-control" type="text" id="checkout-address-2">
              </div>
            </div>
          </div>

          <!-- Navigation (desktop)-->
          <div class="d-none d-lg-flex pt-4">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" to="/checkout/payment"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Payment</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <div class="w-50 pl-2"><a @click="checkout()" class="btn btn-primary btn-block" to="/checkout/complete"><span class="d-none d-sm-inline">Complete order</span><span class="d-inline d-sm-none">Complete</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></a></div>
          </div>
        </section>
        <!-- Sidebar-->
        <aside class="col-lg-4 pt-4 pt-lg-0">
          <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
            <h2 class="h6 text-center mb-4">Order summary</h2>
            <ul class="list-unstyled font-size-sm pb-2 border-bottom">
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Subtotal:</span><span class="text-right">Rp {{ totalHarga }}</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Shipping:</span><span class="text-right">—</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Discount:</span><span class="text-right">—</span></li>
            </ul>
            <h3 class="font-weight-normal text-center my-4">Rp {{ totalHarga }}</h3>
            <form class="needs-validation" method="post" novalidate>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Promo code" required>
                <div class="invalid-feedback">Please provide promo code.</div>
              </div>
              <button class="btn btn-outline-primary btn-block" type="submit">Apply promo code</button>
            </form>
          </div>
        </aside>
      </div>
      <!-- Navigation (mobile)-->
      <div class="row d-lg-none">
        <div class="col-lg-8">
          <div class="d-flex pt-4 mt-3">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" to="/checkout/payment"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Payment</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <div class="w-50 pl-2"><a @click="checkout()" class="btn btn-primary btn-block" to="/checkout/complete"><span class="d-none d-sm-inline">Complete order</span><span class="d-inline d-sm-none">Complete</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></a></div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CheckoutReview',
  components: {

  },

 data() {
    return {
      keranjangUser:[],
      customerInfo: {
        name:'',
        email: '',
        number: '',
        address: ''
      }
    }
  },
  methods: {
    removeItem(index) {
      this.keranjangUser.splice(index,1);
      const parsed = JSON.stringify(this.keranjangUser);
      localStorage.setItem('keranjangUser', parsed);
    },
    checkout(){
      let productIds = this.keranjangUser.map(function(product){
        return product.id
      });

      let checkoutData = {
        'name': this.customerInfo.name,
        'email': this.customerInfo.email,
        'number': this.customerInfo.number,
        'address': this.customerInfo.address,
        "transaction_total": this.totalHarga,
        "transaction_status": "PENDING",
        "transaction_details": productIds
      };

  
       axios
        .post("http://bmsdata.mitra1001.com/api/checkout", checkoutData)
        .then(() => this.$router.push('checkout/complete'))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
 

    }
  },
  mounted() {
      if (localStorage.getItem('keranjangUser')) {
      try {
        this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
      } catch(e) {
        localStorage.removeItem('keranjangUser');
      }
    }
  },
  computed: {
    totalHarga() {
      return this.keranjangUser.reduce(function(items, data){
        return items + data.price;
      },0);
    }
  }

}
</script>