<template>
     <!-- Sidebar-->
        <aside class="col-lg-4 pt-4 pt-lg-0">
          <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
            <div class="widget mb-3" v-if="keranjangUser.length > 0">

              <h2 class="widget-title text-center">Order summary</h2>

              <div class="media align-items-center pb-2 border-bottom"  v-for="keranjang in keranjangUser" :key="keranjang.id" >
                <router-link class="d-block mr-2" v-bind:to="'/product/detail/'+keranjang.id"><img width="64" :src="keranjang.photo" alt="Product"/></router-link>
                <div class="media-body">
                  <h6 class="widget-product-title"><router-link v-bind:to="'/product/detail/'+keranjang.id" >{{ keranjang.name }}</router-link></h6>
                  <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ keranjang.price.toLocaleString('id-ID') }} </span><span class="text-muted">x {{ keranjangUser.length }} </span></div>
                </div>
              </div>
         
            </div>

            <div class="widget mb-3" v-else>
                <div class="media align-items-center">
                  Keranjang kosong
                </div>
            </div>



            <ul class="list-unstyled font-size-sm pb-2 border-bottom">
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Subtotal:</span><span class="text-right">Rp {{ totalHarga.toLocaleString('id-ID') }}</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Shipping:</span><span class="text-right">—</span></li>
              <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Discount:</span><span class="text-right">—</span></li>
            </ul>
            <h3 class="font-weight-normal text-center my-4">Rp {{ totalHarga.toLocaleString('id-ID') }}</h3>
            <form class="needs-validation" method="post" novalidate>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Promo code" required>
                <div class="invalid-feedback">Please provide promo code.</div>
              </div>
              <button class="btn btn-outline-primary btn-block" type="submit">Apply promo code</button>
            </form>
          </div>
        </aside>
</template>

<script>
export default {
    name: 'CheckoutOrderSummary',
        data() {
    return {
      keranjangUser: []

    }

  },
  methods: {
    removeItem(index) {
      this.keranjangUser.splice(index,1);
      const parsed = JSON.stringify(this.keranjangUser);
      localStorage.setItem('keranjangUser', parsed);
    }
  },
  mounted() {
      if (localStorage.getItem('keranjangUser')) {
      try {
        this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
      } catch(e) {
        localStorage.removeItem('keranjangUser');
      }
    }
  },
  computed: {
    totalHarga() {
      return this.keranjangUser.reduce(function(items, data){
        return items + data.price;
      },0);
    }
  }
    
}
</script>