<template>
     <!-- Logo Partner -->
    <section class="container py-lg-4 mb-4">
      <div class="row d-flex justify-content-center">
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/logo-partner/logo1.jpg" alt="Logo Partner" width="70%" height="20px"></a></div>
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/logo-partner/logo2.jpg" alt="Brand" width="100%" ></a></div>
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/logo-partner/logo3.jpg" alt="Brand" width="100%" ></a></div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'LogoPartner'
    
}
</script>