<template>
       <!-- Hero slider-->
    <section class="hero-section">
      <carousel class="hero-items" :items="1" :autoplay="true" :nav="true"  >
          <img src="img/home/slideshow/slide1-100.jpg" alt="">
        <!-- <div class="single-hero-items set-bg" style="background-image: url('img/home/slideshow/slide1-100.jpg')">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Promo</span>
                        <h1 style="color:#FFF;">10th Anniversary<br></h1>
                        <h2>Batumuliashop.com</h2>
                        <p style="color:#FFF;">
                            Dapatkan Voucher <span style="font-size:18px;">20% Discount</span><br>Selama Pembelian di Periode 16-25 Maret 2021
                        </p>
                        <p><small>T&C Apply<br>*minimal pembelian 1 juta<br>*for ready stock items only</small></p>
                        <a href="#" class="primary-btn">Shop Now</a>
                    </div>
                </div>
            </div>
        </div> -->
      </carousel>
    </section>
    <!-- Hero Section End -->
   
  
</template>


<script type="module">
import carousel from 'vue-owl-carousel';

export default {
  name: 'HeroSlider',
  components: {
    carousel
  }
}
  </script>

<style scoped>

</style>
