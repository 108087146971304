import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

// Shop
import ShopDetail from '../views/shop/ShopDetail.vue'
import ShopSubcategory from '../views/shop/ShopSubcategory.vue'

// Account
import AccountWishlist from '../views/account/AccountWishlist.vue'
import AccountOrders from '../views/account/AccountOrders.vue'
import AccountTickets from '../views/account/AccountTickets.vue'
import AccountProfile from '../views/account/AccountProfile.vue'
import AccountAddress from '../views/account/AccountAddress.vue'
import AccountPayment from '../views/account/AccountPayment.vue'
import AccountSignIn from '../views/account/AccountSignin.vue'

// Checkout
import CheckoutDetails from '../views/checkout/CheckoutDetails.vue'
import CheckoutReview from '../views/checkout/CheckoutReview.vue'
import CheckoutShipping from '../views/checkout/CheckoutShipping.vue'
import CheckoutPayment from '../views/checkout/CheckoutPayment.vue'

import Payment from '../views/Payment.vue'

Vue.use(Router)

// const routes = [
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
 
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/informasi',
    name: 'Informasi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/help/Informasi.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contacts.vue')
  },
  {
    path: '/success',
    name: 'Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Success.vue')
  },
  {
    path: '/diamonds',
    props: true,
    name: 'Diamonds',
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopDiamond.vue')
  },
  {
    path: '/jewelry',
    props: true,
    name: 'Jewelry',
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopJewelry.vue')
  },
  {
    path: '/products',
    props: true,
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopGridLs.vue')
  },
    {
    path: '/order-tracking',
    name: 'Order Tracking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderTracking.vue')
  },
   {
    path: '/cart',
    name: 'ShopCart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopCart.vue')
  },

  // SHOP
  {
    path: '/product/gemstones/:subcategories_id',
    props: true,
    name: 'ShopSubcategory',
    component: ShopSubcategory
  },
  {
    path: '/product/detail/:id/:slug',
    props: true,
    name: 'ShopDetail',
    component: ShopDetail
  },

  // ACCOUNT 
  {
    path: '/account/wishlist',
    name: 'AccountWishlist',
    component: AccountWishlist
  },
  {
    path: '/account/orders',
    name: 'AccountOrders',
    component: AccountOrders
  },
  {
    path: '/account/support-tickets',
    name: 'AccountTickets',
    component: AccountTickets
  },
  {
    path: '/account/profile',
    name: 'AccountProfile',
    component: AccountProfile,
    meta: { auth: true }
  },
  {
    path: '/account/address',
    name: 'AccountAddress',
    component: AccountAddress
  },
  {
    path: '/account/payment',
    name: 'AccountPayment',
    component: AccountPayment
  },
  {
    path: '/signin',
    name: 'AccountSignIn',
    component: AccountSignIn
  },
  // CHECKOUT
  {
    path: '/checkout/details',
    name: 'CheckoutDetails',
    component: CheckoutDetails,
    meta: { auth: true }
  },
  {
    path: '/checkout/review',
    name: 'CheckoutReview',
    component: CheckoutReview
  },
  {
    path: '/checkout/shipping',
    name: 'CheckoutShipping',
    component: CheckoutShipping
  },
  {
    path: '/checkout/payment',
    name: 'CheckoutPayment',
    component: CheckoutPayment
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: { auth: true }
  },
  {
    path: '/checkout/complete',
    name: 'CheckoutComplete',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout/CheckoutComplete.vue')
  },
  { path: '*', redirect: '/' }
]
})

router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if(store.getters['auth/guest']){
      // tampilkan pesan bahwa harus login dulu
      store.dispatch('alert/set', {
        status : true,
        text : 'Login first',
        color: 'error',
      })
      store.dispatch('setPrevUrl', to.path) 
      // tampilkan form login
      store.dispatch('dialog/setComponent', 'signin')
      store.dispatch('dialog/setStatus', true)
    }
    else{
      next()
    } 
  }
  else{
      next()
  }
})


// const router = new VueRouter({
//   routes
// })

export default router
