<template>
        <!-- Best Seller-->
    <section class="container ">
      <h2 class="h3 text-center pb-4">Best Seller</h2>
      <div class="row">
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/bestseller/bestseller1.png" alt="Brand" width="100%" ></a></div>
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/bestseller/bestseller2.png" alt="Brand" width="100%" ></a></div>
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/bestseller/bestseller3.png" alt="Brand" width="100%" ></a></div>
        <div class="col-md-3 col-sm-4 col-6"><a class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" href="#"><img class="d-block mx-auto" src="img/home/bestseller/bestseller4.png" alt="Brand" width="100%" ></a></div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'ProductBestSeller'
    
}
</script>