<template>
<div class="checkoutshipping">
    <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="shop-grid-ls.html">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Checkout</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <section class="col-lg-8">
          <!-- Steps-->
          <div class="steps steps-light pt-2 pb-3 mb-5"><a class="step-item active" href="shop-cart.html">
              <div class="step-progress"><span class="step-count">1</span></div>
              <div class="step-label"><i class="czi-cart"></i>Cart</div></a><a class="step-item active" href="checkout-details.html">
              <div class="step-progress"><span class="step-count">2</span></div>
              <div class="step-label"><i class="czi-user-circle"></i>Your details</div></a><a class="step-item active current" href="checkout-shipping.html">
              <div class="step-progress"><span class="step-count">3</span></div>
              <div class="step-label"><i class="czi-package"></i>Shipping</div></a><a class="step-item" href="checkout-payment.html">
              <div class="step-progress"><span class="step-count">4</span></div>
              <div class="step-label"><i class="czi-card"></i>Payment</div></a><a class="step-item" href="checkout-review.html">
              <div class="step-progress"><span class="step-count">5</span></div>
              <div class="step-label"><i class="czi-check-circle"></i>Review</div></a></div>
          <!-- Shipping methods table-->
          <h2 class="h6 pb-3 mb-2">Choose shipping method</h2>
          <div class="table-responsive">
            <table class="table table-hover font-size-sm border-bottom">
              <thead>
                <tr>
                  <th class="align-middle"></th>
                  <th class="align-middle">Shipping method</th>
                  <th class="align-middle">Delivery time</th>
                  <th class="align-middle">Handling fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="courier" name="shipping-method" checked>
                      <label class="custom-control-label" for="courier"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Courier</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                  <td class="align-middle">2 - 4 days</td>
                  <td class="align-middle">$26.50</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="local" name="shipping-method">
                      <label class="custom-control-label" for="local"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Local Shipping</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                  <td class="align-middle">up to one week</td>
                  <td class="align-middle">$10.00</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="flat" name="shipping-method">
                      <label class="custom-control-label" for="flat"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Flat Rate</span><br><span class="text-muted">All addresses (default zone)</span></td>
                  <td class="align-middle">5 - 7 days</td>
                  <td class="align-middle">$33.85</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="ups" name="shipping-method">
                      <label class="custom-control-label" for="ups"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">UPS Ground Shipping</span><br><span class="text-muted">All addresses (default zone)</span></td>
                  <td class="align-middle">4 - 6 days</td>
                  <td class="align-middle">$18.00</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="pickup" name="shipping-method">
                      <label class="custom-control-label" for="pickup"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Local Pickup from store</span><br><span class="text-muted">All addresses (default zone)</span></td>
                  <td class="align-middle">&mdash;</td>
                  <td class="align-middle">$0.00</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="locker" name="shipping-method">
                      <label class="custom-control-label" for="locker"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Pick Up at Cartzilla Locker</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                  <td class="align-middle">&mdash;</td>
                  <td class="align-middle">$9.99</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="global-export" name="shipping-method">
                      <label class="custom-control-label" for="global-export"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Cartzilla Global Export</span><br><span class="text-muted font-size-sm">All addresses (default zone), outside United States</span></td>
                  <td class="align-middle">3 - 4 days</td>
                  <td class="align-middle">$25.00</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="same-day" name="shipping-method">
                      <label class="custom-control-label" for="same-day"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">Same-Day Delivery</span><br><span class="text-muted">Only United States</span></td>
                  <td class="align-middle">&mdash;</td>
                  <td class="align-middle">$34.00</td>
                </tr>
                <tr>
                  <td>
                    <div class="custom-control custom-radio mb-4">
                      <input class="custom-control-input" type="radio" id="international" name="shipping-method">
                      <label class="custom-control-label" for="international"></label>
                    </div>
                  </td>
                  <td class="align-middle"><span class="text-dark font-weight-medium">International Shipping</span><br><span class="text-muted">All addresses (default zone)</span></td>
                  <td class="align-middle">up to 15 days</td>
                  <td class="align-middle">$27.00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Navigation (desktop)-->
          <div class="d-none d-lg-flex pt-4">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" to="/checkout/details"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Adresses</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <div class="w-50 pl-2"><router-link class="btn btn-primary btn-block" to="/checkout/payment"><span class="d-none d-sm-inline">Proceed to Payment</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></router-link></div>
          </div>
        </section>
        <!-- Sidebar-->
        <CheckoutOrderSummary />

      </div>
      <!-- Navigation (mobile)-->
      <div class="row d-lg-none">
        <div class="col-lg-8">
          <div class="d-flex pt-4 mt-3">
            <div class="w-50 pr-3"><router-link class="btn btn-secondary btn-block" href="/checkout/details"><i class="czi-arrow-left mt-sm-0 mr-1"></i><span class="d-none d-sm-inline">Back to Adresses</span><span class="d-inline d-sm-none">Back</span></router-link></div>
            <div class="w-50 pl-2"><router-link class="btn btn-primary btn-block" href="/checkout/payment"><span class="d-none d-sm-inline">Proceed to Payment</span><span class="d-inline d-sm-none">Next</span><i class="czi-arrow-right mt-sm-0 ml-1"></i></router-link></div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import CheckoutOrderSummary from '@/components/CheckoutOrderSummary.vue'

export default {
  name: 'CheckoutShipping',
  components: {
    CheckoutOrderSummary

  }

}
</script>